import { Col, Grid, Row, Section } from '../../../../theme/components';
import { LocalLink } from '../../../../components';
import { Text, Heading as Title } from '../../../../theme/components/Typography';
import PropTypes from 'prop-types';
import React from 'react';

export const Heading = props => {
  const { backToHomeLabel, heading, date } = props;

  const documentDate = new Date(date);

  return (
    <Section mediumPadding mt={{ sm: 0, xs: 2 }}>
      <Grid>
        <Row center="xs">
          <Col left md={8} sm={10} xs={12}>
            <LocalLink href="/" mb={{ sm: 3.5, xs: 2.5 }}>
              <Text XSmall underline>
                {backToHomeLabel}
              </Text>
            </LocalLink>
            {date && (
              <Text XSmall isLabel mb={0.5} n04>
                {documentDate.toDateString()}
              </Text>
            )}
            <Title h2 left>
              {heading}
            </Title>
          </Col>
        </Row>
      </Grid>
    </Section>
  );
};

Heading.propTypes = {
  backToHomeLabel: PropTypes.string,
  date: PropTypes.string,
  heading: PropTypes.string
};
