import { Col, DocumentStyle, Grid, Row, Section } from '../../../../theme/components';
import { RichText } from 'prismic-react-tools';
import PropTypes from 'prop-types';
import React from 'react';

export const Body = props => {
  const { content } = props;

  return (
    <Section pb={{ sm: 10, xs: 5 }} pt={{ sm: 4, xs: 1.5 }}>
      <Grid>
        <Row center="xs">
          <Col left md={8} sm={10} xs={12}>
            <DocumentStyle>
              <RichText content={content} />
            </DocumentStyle>
          </Col>
        </Row>
      </Grid>
    </Section>
  );
};

Body.propTypes = {
  content: PropTypes.any
};
